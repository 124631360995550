import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _7ebc03d4 = () => interopDefault(import('../pages/application-stored.vue' /* webpackChunkName: "pages/application-stored" */))
const _190d2a94 = () => interopDefault(import('../pages/autolaina.vue' /* webpackChunkName: "pages/autolaina" */))
const _752fd17c = () => interopDefault(import('../pages/autorahoitus.vue' /* webpackChunkName: "pages/autorahoitus" */))
const _687ea4ec = () => interopDefault(import('../pages/cc-new/index.vue' /* webpackChunkName: "pages/cc-new/index" */))
const _4c794300 = () => interopDefault(import('../pages/cc-new/index/kuluttaja.vue' /* webpackChunkName: "pages/cc-new/index/kuluttaja" */))
const _6eb4641a = () => interopDefault(import('../pages/cc-new/index/yritys.vue' /* webpackChunkName: "pages/cc-new/index/yritys" */))
const _1d515fac = () => interopDefault(import('../pages/etutuoterahoitus.vue' /* webpackChunkName: "pages/etutuoterahoitus" */))
const _7c6b8918 = () => interopDefault(import('../pages/identiteettivakuutus.vue' /* webpackChunkName: "pages/identiteettivakuutus" */))
const _fa1a4090 = () => interopDefault(import('../pages/iframe.vue' /* webpackChunkName: "pages/iframe" */))
const _0ed5830c = () => interopDefault(import('../pages/iframe/leadgroup.vue' /* webpackChunkName: "pages/iframe/leadgroup" */))
const _6f933854 = () => interopDefault(import('../pages/iframe-yrityslaina.vue' /* webpackChunkName: "pages/iframe-yrityslaina" */))
const _d83aa240 = () => interopDefault(import('../pages/iframe-yrityslaina/yrityslainaheti.vue' /* webpackChunkName: "pages/iframe-yrityslaina/yrityslainaheti" */))
const _d1561a30 = () => interopDefault(import('../pages/jarjestelylaina.vue' /* webpackChunkName: "pages/jarjestelylaina" */))
const _204d909b = () => interopDefault(import('../pages/joustoluotto.vue' /* webpackChunkName: "pages/joustoluotto" */))
const _58e2e556 = () => interopDefault(import('../pages/kilpailuta-lainat.vue' /* webpackChunkName: "pages/kilpailuta-lainat" */))
const _bf8b190c = () => interopDefault(import('../pages/kokemuksia.vue' /* webpackChunkName: "pages/kokemuksia" */))
const _6e8cf10e = () => interopDefault(import('../pages/lainaa.vue' /* webpackChunkName: "pages/lainaa" */))
const _169f4e16 = () => interopDefault(import('../pages/lainaa-1000-euroa.vue' /* webpackChunkName: "pages/lainaa-1000-euroa" */))
const _c6cd8b8e = () => interopDefault(import('../pages/lainaa-10000-euroa.vue' /* webpackChunkName: "pages/lainaa-10000-euroa" */))
const _7e7703d8 = () => interopDefault(import('../pages/lainaa-11000-euroa.vue' /* webpackChunkName: "pages/lainaa-11000-euroa" */))
const _05ee2a54 = () => interopDefault(import('../pages/lainaa-15000-euroa.vue' /* webpackChunkName: "pages/lainaa-15000-euroa" */))
const _1316b70c = () => interopDefault(import('../pages/lainaa-20000-euroa.vue' /* webpackChunkName: "pages/lainaa-20000-euroa" */))
const _5fc99495 = () => interopDefault(import('../pages/lainaa-25000-euroa.vue' /* webpackChunkName: "pages/lainaa-25000-euroa" */))
const _50500ebb = () => interopDefault(import('../pages/lainaa-30000-euroa.vue' /* webpackChunkName: "pages/lainaa-30000-euroa" */))
const _aba90e08 = () => interopDefault(import('../pages/lainaa-40000-euroa.vue' /* webpackChunkName: "pages/lainaa-40000-euroa" */))
const _7c277f71 = () => interopDefault(import('../pages/lainaa-5000-euroa.vue' /* webpackChunkName: "pages/lainaa-5000-euroa" */))
const _0406e33d = () => interopDefault(import('../pages/lainaa-50000-euroa.vue' /* webpackChunkName: "pages/lainaa-50000-euroa" */))
const _5de24d7e = () => interopDefault(import('../pages/lainaa-60000-euroa.vue' /* webpackChunkName: "pages/lainaa-60000-euroa" */))
const _90849082 = () => interopDefault(import('../pages/lainaa-70000-euroa.vue' /* webpackChunkName: "pages/lainaa-70000-euroa" */))
const _4d0e6445 = () => interopDefault(import('../pages/lainahakemus.vue' /* webpackChunkName: "pages/lainahakemus" */))
const _4c8a10c8 = () => interopDefault(import('../pages/lainakumppanit.vue' /* webpackChunkName: "pages/lainakumppanit" */))
const _108995c5 = () => interopDefault(import('../pages/lainavertailu.vue' /* webpackChunkName: "pages/lainavertailu" */))
const _5c792a86 = () => interopDefault(import('../pages/luotto.vue' /* webpackChunkName: "pages/luotto" */))
const _23e8d59a = () => interopDefault(import('../pages/markkinointikielto.vue' /* webpackChunkName: "pages/markkinointikielto" */))
const _487411c4 = () => interopDefault(import('../pages/mmkisat.vue' /* webpackChunkName: "pages/mmkisat" */))
const _08d36e02 = () => interopDefault(import('../pages/moottoripyoralaina.vue' /* webpackChunkName: "pages/moottoripyoralaina" */))
const _3a601fa2 = () => interopDefault(import('../pages/oma/index.vue' /* webpackChunkName: "pages/oma/index" */))
const _5459a013 = () => interopDefault(import('../pages/remonttilaina.vue' /* webpackChunkName: "pages/remonttilaina" */))
const _d6269782 = () => interopDefault(import('../pages/sijoituslaina.vue' /* webpackChunkName: "pages/sijoituslaina" */))
const _71cf1b16 = () => interopDefault(import('../pages/smskielto.vue' /* webpackChunkName: "pages/smskielto" */))
const _834e7736 = () => interopDefault(import('../pages/talous/index.vue' /* webpackChunkName: "pages/talous/index" */))
const _17f61eac = () => interopDefault(import('../pages/tietoja-evasteista.vue' /* webpackChunkName: "pages/tietoja-evasteista" */))
const _2677c17d = () => interopDefault(import('../pages/venelaina.vue' /* webpackChunkName: "pages/venelaina" */))
const _74d55e42 = () => interopDefault(import('../pages/yhdista-lainat.vue' /* webpackChunkName: "pages/yhdista-lainat" */))
const _67abb1c4 = () => interopDefault(import('../pages/yhdistelylaina.vue' /* webpackChunkName: "pages/yhdistelylaina" */))
const _36617d92 = () => interopDefault(import('../pages/yhteistyokumppanit/index.vue' /* webpackChunkName: "pages/yhteistyokumppanit/index" */))
const _51684420 = () => interopDefault(import('../pages/yleiset-ehdot.vue' /* webpackChunkName: "pages/yleiset-ehdot" */))
const _a3bcf60c = () => interopDefault(import('../pages/yrityksen-rahoitus.vue' /* webpackChunkName: "pages/yrityksen-rahoitus" */))
const _162a6832 = () => interopDefault(import('../pages/yritys.vue' /* webpackChunkName: "pages/yritys" */))
const _b72b19da = () => interopDefault(import('../pages/yrityslainaa-5000-euroa.vue' /* webpackChunkName: "pages/yrityslainaa-5000-euroa" */))
const _064a9066 = () => interopDefault(import('../pages/yrityslainakumppanit.vue' /* webpackChunkName: "pages/yrityslainakumppanit" */))
const _552ca582 = () => interopDefault(import('../pages/yrityslainat.vue' /* webpackChunkName: "pages/yrityslainat" */))
const _b081179e = () => interopDefault(import('../pages/yritysrahoitus.vue' /* webpackChunkName: "pages/yritysrahoitus" */))
const _d52f5ebe = () => interopDefault(import('../pages/cc-new/login.vue' /* webpackChunkName: "pages/cc-new/login" */))
const _1f18c02f = () => interopDefault(import('../pages/talous/ala-hae-lainaa-mista-tahansa.vue' /* webpackChunkName: "pages/talous/ala-hae-lainaa-mista-tahansa" */))
const _5fbbd2d0 = () => interopDefault(import('../pages/talous/auton-hankinnassa-voi-saastaa-reilusti-kilpailuttamalla.vue' /* webpackChunkName: "pages/talous/auton-hankinnassa-voi-saastaa-reilusti-kilpailuttamalla" */))
const _13dfeab0 = () => interopDefault(import('../pages/talous/essi-ja-jere-hyodynsivat-lainojen-korkokaton.vue' /* webpackChunkName: "pages/talous/essi-ja-jere-hyodynsivat-lainojen-korkokaton" */))
const _2dd78d86 = () => interopDefault(import('../pages/talous/haaveiletko-urheilureissusta.vue' /* webpackChunkName: "pages/talous/haaveiletko-urheilureissusta" */))
const _1cb056d8 = () => interopDefault(import('../pages/talous/hakeako-yrityslaina-perinteisesti-pankista-vai-nopeasti-ilman-vakuuksia.vue' /* webpackChunkName: "pages/talous/hakeako-yrityslaina-perinteisesti-pankista-vai-nopeasti-ilman-vakuuksia" */))
const _6eae9792 = () => interopDefault(import('../pages/talous/kansainvaliset-digipankit-ovat-myllyttaneet-suomen-lainamarkkinat.vue' /* webpackChunkName: "pages/talous/kansainvaliset-digipankit-ovat-myllyttaneet-suomen-lainamarkkinat" */))
const _2bde36a4 = () => interopDefault(import('../pages/talous/katso-5-kohdan-listaus-jolla-helpotat-taloutesi-hoitoa.vue' /* webpackChunkName: "pages/talous/katso-5-kohdan-listaus-jolla-helpotat-taloutesi-hoitoa" */))
const _e677f1ae = () => interopDefault(import('../pages/talous/korkokatto-paattyy-joulukuussa.vue' /* webpackChunkName: "pages/talous/korkokatto-paattyy-joulukuussa" */))
const _3306a0cb = () => interopDefault(import('../pages/talous/korkokatto-putosi-kymmeneen-prosenttiin.vue' /* webpackChunkName: "pages/talous/korkokatto-putosi-kymmeneen-prosenttiin" */))
const _435857fa = () => interopDefault(import('../pages/talous/korkokattoa-jatkettiin-syksyyn.vue' /* webpackChunkName: "pages/talous/korkokattoa-jatkettiin-syksyyn" */))
const _67ce2c8c = () => interopDefault(import('../pages/talous/kulutusluottojen-korot-laskivat-korkokaton-seurauksena.vue' /* webpackChunkName: "pages/talous/kulutusluottojen-korot-laskivat-korkokaton-seurauksena" */))
const _e3d69ae8 = () => interopDefault(import('../pages/talous/kulutusluottojen-korot-putosivat-selvasti.vue' /* webpackChunkName: "pages/talous/kulutusluottojen-korot-putosivat-selvasti" */))
const _34f46d1a = () => interopDefault(import('../pages/talous/kulutusluottojen-lakimuutos-sai-suomalaiset-liikkeelle.vue' /* webpackChunkName: "pages/talous/kulutusluottojen-lakimuutos-sai-suomalaiset-liikkeelle" */))
const _1e2ff200 = () => interopDefault(import('../pages/talous/kulutusluottojen-saannot-uusiksi-nain-hyodyt-korkokatosta.vue' /* webpackChunkName: "pages/talous/kulutusluottojen-saannot-uusiksi-nain-hyodyt-korkokatosta" */))
const _42c974f6 = () => interopDefault(import('../pages/talous/lainat-kannattaa-yhdistaa-heti.vue' /* webpackChunkName: "pages/talous/lainat-kannattaa-yhdistaa-heti" */))
const _3e93226a = () => interopDefault(import('../pages/talous/lainojen-yhdistaminen-kannatti.vue' /* webpackChunkName: "pages/talous/lainojen-yhdistaminen-kannatti" */))
const _ec349758 = () => interopDefault(import('../pages/talous/mita-tekisit-200-eurolla.vue' /* webpackChunkName: "pages/talous/mita-tekisit-200-eurolla" */))
const _58ccb379 = () => interopDefault(import('../pages/talous/miten-yrityslainoja-on-jarkevin-ja-helpoin-vertailla.vue' /* webpackChunkName: "pages/talous/miten-yrityslainoja-on-jarkevin-ja-helpoin-vertailla" */))
const _54c72711 = () => interopDefault(import('../pages/talous/nain-loydat-luotettavan-lainan-edullisella-korolla.vue' /* webpackChunkName: "pages/talous/nain-loydat-luotettavan-lainan-edullisella-korolla" */))
const _50baba25 = () => interopDefault(import('../pages/talous/nain-selviat-kasvun-haasteista-ja-yllattavista-tilanteista.vue' /* webpackChunkName: "pages/talous/nain-selviat-kasvun-haasteista-ja-yllattavista-tilanteista" */))
const _5a8dd4e6 = () => interopDefault(import('../pages/talous/oletko-jo-kokeillut-lainojen-kilpailuttamista-verkossa.vue' /* webpackChunkName: "pages/talous/oletko-jo-kokeillut-lainojen-kilpailuttamista-verkossa" */))
const _5ee7ed28 = () => interopDefault(import('../pages/talous/petri-yhdisti-pienet-lainansa-ja-hammastyi.vue' /* webpackChunkName: "pages/talous/petri-yhdisti-pienet-lainansa-ja-hammastyi" */))
const _8f50e5c4 = () => interopDefault(import('../pages/talous/piia-saastaa-yli-300-euroa-kuukaudessa-laskeneen-korkokaton-ansiosta.vue' /* webpackChunkName: "pages/talous/piia-saastaa-yli-300-euroa-kuukaudessa-laskeneen-korkokaton-ansiosta" */))
const _d53937ee = () => interopDefault(import('../pages/talous/pitkaan-odotettu-reissu-edessa-ja-rahat-vahissa.vue' /* webpackChunkName: "pages/talous/pitkaan-odotettu-reissu-edessa-ja-rahat-vahissa" */))
const _36d0c87a = () => interopDefault(import('../pages/talous/teija-yhdisti-lainansa-ja-saastaa-nyt-kuluissa.vue' /* webpackChunkName: "pages/talous/teija-yhdisti-lainansa-ja-saastaa-nyt-kuluissa" */))
const _6b80a2a0 = () => interopDefault(import('../pages/talous/vakuudettomien-lainojen-korkoja-rajoittava-laki-astui-voimaan.vue' /* webpackChunkName: "pages/talous/vakuudettomien-lainojen-korkoja-rajoittava-laki-astui-voimaan" */))
const _06e9baae = () => interopDefault(import('../pages/yhteistyokumppanit/_company.vue' /* webpackChunkName: "pages/yhteistyokumppanit/_company" */))
const _2bb9f324 = () => interopDefault(import('../pages/index.vue' /* webpackChunkName: "pages/index" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/application-stored",
    component: _7ebc03d4,
    name: "application-stored"
  }, {
    path: "/autolaina",
    component: _190d2a94,
    name: "autolaina"
  }, {
    path: "/autorahoitus",
    component: _752fd17c,
    name: "autorahoitus"
  }, {
    path: "/cc-new",
    component: _687ea4ec,
    name: "cc-new",
    children: [{
      path: "kuluttaja",
      component: _4c794300,
      name: "cc-new-index-kuluttaja"
    }, {
      path: "yritys",
      component: _6eb4641a,
      name: "cc-new-index-yritys"
    }]
  }, {
    path: "/etutuoterahoitus",
    component: _1d515fac,
    name: "etutuoterahoitus"
  }, {
    path: "/identiteettivakuutus",
    component: _7c6b8918,
    name: "identiteettivakuutus"
  }, {
    path: "/iframe",
    component: _fa1a4090,
    name: "iframe",
    children: [{
      path: "leadgroup",
      component: _0ed5830c,
      name: "iframe-leadgroup"
    }]
  }, {
    path: "/iframe-yrityslaina",
    component: _6f933854,
    name: "iframe-yrityslaina",
    children: [{
      path: "yrityslainaheti",
      component: _d83aa240,
      name: "iframe-yrityslaina-yrityslainaheti"
    }]
  }, {
    path: "/jarjestelylaina",
    component: _d1561a30,
    name: "jarjestelylaina"
  }, {
    path: "/joustoluotto",
    component: _204d909b,
    name: "joustoluotto"
  }, {
    path: "/kilpailuta-lainat",
    component: _58e2e556,
    name: "kilpailuta-lainat"
  }, {
    path: "/kokemuksia",
    component: _bf8b190c,
    name: "kokemuksia"
  }, {
    path: "/lainaa",
    component: _6e8cf10e,
    name: "lainaa"
  }, {
    path: "/lainaa-1000-euroa",
    component: _169f4e16,
    name: "lainaa-1000-euroa"
  }, {
    path: "/lainaa-10000-euroa",
    component: _c6cd8b8e,
    name: "lainaa-10000-euroa"
  }, {
    path: "/lainaa-11000-euroa",
    component: _7e7703d8,
    name: "lainaa-11000-euroa"
  }, {
    path: "/lainaa-15000-euroa",
    component: _05ee2a54,
    name: "lainaa-15000-euroa"
  }, {
    path: "/lainaa-20000-euroa",
    component: _1316b70c,
    name: "lainaa-20000-euroa"
  }, {
    path: "/lainaa-25000-euroa",
    component: _5fc99495,
    name: "lainaa-25000-euroa"
  }, {
    path: "/lainaa-30000-euroa",
    component: _50500ebb,
    name: "lainaa-30000-euroa"
  }, {
    path: "/lainaa-40000-euroa",
    component: _aba90e08,
    name: "lainaa-40000-euroa"
  }, {
    path: "/lainaa-5000-euroa",
    component: _7c277f71,
    name: "lainaa-5000-euroa"
  }, {
    path: "/lainaa-50000-euroa",
    component: _0406e33d,
    name: "lainaa-50000-euroa"
  }, {
    path: "/lainaa-60000-euroa",
    component: _5de24d7e,
    name: "lainaa-60000-euroa"
  }, {
    path: "/lainaa-70000-euroa",
    component: _90849082,
    name: "lainaa-70000-euroa"
  }, {
    path: "/lainahakemus",
    component: _4d0e6445,
    name: "lainahakemus"
  }, {
    path: "/lainakumppanit",
    component: _4c8a10c8,
    name: "lainakumppanit"
  }, {
    path: "/lainavertailu",
    component: _108995c5,
    name: "lainavertailu"
  }, {
    path: "/luotto",
    component: _5c792a86,
    name: "luotto"
  }, {
    path: "/markkinointikielto",
    component: _23e8d59a,
    name: "markkinointikielto"
  }, {
    path: "/mmkisat",
    component: _487411c4,
    name: "mmkisat"
  }, {
    path: "/moottoripyoralaina",
    component: _08d36e02,
    name: "moottoripyoralaina"
  }, {
    path: "/oma",
    component: _3a601fa2,
    name: "oma"
  }, {
    path: "/remonttilaina",
    component: _5459a013,
    name: "remonttilaina"
  }, {
    path: "/sijoituslaina",
    component: _d6269782,
    name: "sijoituslaina"
  }, {
    path: "/smskielto",
    component: _71cf1b16,
    name: "smskielto"
  }, {
    path: "/talous",
    component: _834e7736,
    name: "talous"
  }, {
    path: "/tietoja-evasteista",
    component: _17f61eac,
    name: "tietoja-evasteista"
  }, {
    path: "/venelaina",
    component: _2677c17d,
    name: "venelaina"
  }, {
    path: "/yhdista-lainat",
    component: _74d55e42,
    name: "yhdista-lainat"
  }, {
    path: "/yhdistelylaina",
    component: _67abb1c4,
    name: "yhdistelylaina"
  }, {
    path: "/yhteistyokumppanit",
    component: _36617d92,
    name: "yhteistyokumppanit"
  }, {
    path: "/yleiset-ehdot",
    component: _51684420,
    name: "yleiset-ehdot"
  }, {
    path: "/yrityksen-rahoitus",
    component: _a3bcf60c,
    name: "yrityksen-rahoitus"
  }, {
    path: "/yritys",
    component: _162a6832,
    name: "yritys"
  }, {
    path: "/yrityslainaa-5000-euroa",
    component: _b72b19da,
    name: "yrityslainaa-5000-euroa"
  }, {
    path: "/yrityslainakumppanit",
    component: _064a9066,
    name: "yrityslainakumppanit"
  }, {
    path: "/yrityslainat",
    component: _552ca582,
    name: "yrityslainat"
  }, {
    path: "/yritysrahoitus",
    component: _b081179e,
    name: "yritysrahoitus"
  }, {
    path: "/cc-new/login",
    component: _d52f5ebe,
    name: "cc-new-login"
  }, {
    path: "/talous/ala-hae-lainaa-mista-tahansa",
    component: _1f18c02f,
    name: "talous-ala-hae-lainaa-mista-tahansa"
  }, {
    path: "/talous/auton-hankinnassa-voi-saastaa-reilusti-kilpailuttamalla",
    component: _5fbbd2d0,
    name: "talous-auton-hankinnassa-voi-saastaa-reilusti-kilpailuttamalla"
  }, {
    path: "/talous/essi-ja-jere-hyodynsivat-lainojen-korkokaton",
    component: _13dfeab0,
    name: "talous-essi-ja-jere-hyodynsivat-lainojen-korkokaton"
  }, {
    path: "/talous/haaveiletko-urheilureissusta",
    component: _2dd78d86,
    name: "talous-haaveiletko-urheilureissusta"
  }, {
    path: "/talous/hakeako-yrityslaina-perinteisesti-pankista-vai-nopeasti-ilman-vakuuksia",
    component: _1cb056d8,
    name: "talous-hakeako-yrityslaina-perinteisesti-pankista-vai-nopeasti-ilman-vakuuksia"
  }, {
    path: "/talous/kansainvaliset-digipankit-ovat-myllyttaneet-suomen-lainamarkkinat",
    component: _6eae9792,
    name: "talous-kansainvaliset-digipankit-ovat-myllyttaneet-suomen-lainamarkkinat"
  }, {
    path: "/talous/katso-5-kohdan-listaus-jolla-helpotat-taloutesi-hoitoa",
    component: _2bde36a4,
    name: "talous-katso-5-kohdan-listaus-jolla-helpotat-taloutesi-hoitoa"
  }, {
    path: "/talous/korkokatto-paattyy-joulukuussa",
    component: _e677f1ae,
    name: "talous-korkokatto-paattyy-joulukuussa"
  }, {
    path: "/talous/korkokatto-putosi-kymmeneen-prosenttiin",
    component: _3306a0cb,
    name: "talous-korkokatto-putosi-kymmeneen-prosenttiin"
  }, {
    path: "/talous/korkokattoa-jatkettiin-syksyyn",
    component: _435857fa,
    name: "talous-korkokattoa-jatkettiin-syksyyn"
  }, {
    path: "/talous/kulutusluottojen-korot-laskivat-korkokaton-seurauksena",
    component: _67ce2c8c,
    name: "talous-kulutusluottojen-korot-laskivat-korkokaton-seurauksena"
  }, {
    path: "/talous/kulutusluottojen-korot-putosivat-selvasti",
    component: _e3d69ae8,
    name: "talous-kulutusluottojen-korot-putosivat-selvasti"
  }, {
    path: "/talous/kulutusluottojen-lakimuutos-sai-suomalaiset-liikkeelle",
    component: _34f46d1a,
    name: "talous-kulutusluottojen-lakimuutos-sai-suomalaiset-liikkeelle"
  }, {
    path: "/talous/kulutusluottojen-saannot-uusiksi-nain-hyodyt-korkokatosta",
    component: _1e2ff200,
    name: "talous-kulutusluottojen-saannot-uusiksi-nain-hyodyt-korkokatosta"
  }, {
    path: "/talous/lainat-kannattaa-yhdistaa-heti",
    component: _42c974f6,
    name: "talous-lainat-kannattaa-yhdistaa-heti"
  }, {
    path: "/talous/lainojen-yhdistaminen-kannatti",
    component: _3e93226a,
    name: "talous-lainojen-yhdistaminen-kannatti"
  }, {
    path: "/talous/mita-tekisit-200-eurolla",
    component: _ec349758,
    name: "talous-mita-tekisit-200-eurolla"
  }, {
    path: "/talous/miten-yrityslainoja-on-jarkevin-ja-helpoin-vertailla",
    component: _58ccb379,
    name: "talous-miten-yrityslainoja-on-jarkevin-ja-helpoin-vertailla"
  }, {
    path: "/talous/nain-loydat-luotettavan-lainan-edullisella-korolla",
    component: _54c72711,
    name: "talous-nain-loydat-luotettavan-lainan-edullisella-korolla"
  }, {
    path: "/talous/nain-selviat-kasvun-haasteista-ja-yllattavista-tilanteista",
    component: _50baba25,
    name: "talous-nain-selviat-kasvun-haasteista-ja-yllattavista-tilanteista"
  }, {
    path: "/talous/oletko-jo-kokeillut-lainojen-kilpailuttamista-verkossa",
    component: _5a8dd4e6,
    name: "talous-oletko-jo-kokeillut-lainojen-kilpailuttamista-verkossa"
  }, {
    path: "/talous/petri-yhdisti-pienet-lainansa-ja-hammastyi",
    component: _5ee7ed28,
    name: "talous-petri-yhdisti-pienet-lainansa-ja-hammastyi"
  }, {
    path: "/talous/piia-saastaa-yli-300-euroa-kuukaudessa-laskeneen-korkokaton-ansiosta",
    component: _8f50e5c4,
    name: "talous-piia-saastaa-yli-300-euroa-kuukaudessa-laskeneen-korkokaton-ansiosta"
  }, {
    path: "/talous/pitkaan-odotettu-reissu-edessa-ja-rahat-vahissa",
    component: _d53937ee,
    name: "talous-pitkaan-odotettu-reissu-edessa-ja-rahat-vahissa"
  }, {
    path: "/talous/teija-yhdisti-lainansa-ja-saastaa-nyt-kuluissa",
    component: _36d0c87a,
    name: "talous-teija-yhdisti-lainansa-ja-saastaa-nyt-kuluissa"
  }, {
    path: "/talous/vakuudettomien-lainojen-korkoja-rajoittava-laki-astui-voimaan",
    component: _6b80a2a0,
    name: "talous-vakuudettomien-lainojen-korkoja-rajoittava-laki-astui-voimaan"
  }, {
    path: "/yhteistyokumppanit/:company",
    component: _06e9baae,
    name: "yhteistyokumppanit-company"
  }, {
    path: "/",
    component: _2bb9f324,
    name: "index"
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
